export const environment = {
  production: true,
  apiUrl: "https://test-loyalty.planetvg.nl",
  stsServer: "https://test-auth.planetvg.nl/realms/planetvg",
  stripe_apiKey: "pk_test_51O2eztAhFQpySfmxVhcTavVRLoDkYoJgLqglez3vEwTq47QovQ6SvBflEsbG6V2O9FJuPHBmmr4b0YTSDj5p21oT00okYDKNQF",
  accountPortalUrl: "https://test-account.planetvg.nl",
  // Crude Feature Flag
  enabledCheckout : true,
  storefrontUrl : "https://test-www.planetvg.nl",
};
